<template>
  <div class="page-container schedule-page">
    <portal to="page-name">Configuração de agenda</portal>
    <div class="loading mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">
          Configuração de agenda
          <span v-if="form.name"> - </span>
          <small class="text-secondary"> {{ form.name }}</small>
        </div>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-8 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome da agenda</label>
            <div class="input-group">
              <input type="text" id="name" name="name" class="form-input"
                     placeholder="Dr José da Silva / Eletrocardiograma / Mapa 24 horas"
                     autocomplete="nope"
                     v-model="form.name" @change="$v.form.name.$touch()">
            </div>
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-4 form-group"
               :class="{'has-error': $v.form.type.$error}">
            <label for="name" class="form-label">Tipo</label>
            <select class="form-select" v-model="form.type">
              <option v-for="(text, value) in scheduleTypes"
                      :value="value" :key="value">{{ text }}</option>
            </select>
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.type.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 form-group">
            <label class="form-switch">
              <input type="checkbox" v-model="form.communication.whatsapp">
              <i class="form-icon"></i>
              <span>Permitir o envio de comunicações por WhatsApp</span>
            </label>
          </div>
          <div class="column col-6 form-group">
            <label class="form-switch">
              <input type="checkbox" v-model="form.communication.email">
              <i class="form-icon"></i>
              <span>Permitir o envio de comunicações por e-mail</span>
            </label>
          </div>
        </div>
        <div class="form-group" v-if="schedules.length > 0 && form.type !== 'surgery'">
          <div class="d-flex h-centered">
            <fa-icon
              :icon="['fal', isShared ? 'check-circle': 'times-circle']"
              :class="[isShared ? 'text-success': '']"
            ></fa-icon>
            <span class="ml-2 mr-2">Compartilhamento da agenda na web</span>
            <button
              v-if="share.show"
              class="btn btn-sm btn-error btn-icon btn-icon-left"
              @click="share.show = false"
            ><fa-icon :icon="['fal', 'times']"></fa-icon> Fechar</button>
            <button
              v-else-if="isShared"
              class="btn btn-sm btn-primary btn-icon btn-icon-left"
              @click="loadShareData"
            ><fa-icon :icon="['fal', 'cog']"></fa-icon> Configurar</button>
            <button
              v-else
              class="btn btn-sm btn-primary btn-icon btn-icon-left"
              @click="loadShareData"
            ><fa-icon :icon="['fal', 'pencil']"></fa-icon> Ativar</button>
          </div>

          <div class="card card-share" v-if="share.show">
            <div class="card-header">
              <div class="h6">Compartilhar agenda</div>
            </div>
            <div class="loading loading-lg" v-if="share.loading" />
            <div class="card-body" v-else>
              <table class="table mb-2">
                <thead>
                <tr>
                  <th style="width:30px">
                    <label class="form-checkbox">
                      <input type="checkbox"
                             @change="selectAllInsurances"
                             :checked="getInsuranceSelected"
                             :indeterminate.prop="getInsuranceSelected === null">
                      <i class="form-icon"></i>
                    </label>
                  </th>
                  <th>Selecionar todos os convênios</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in share.insurances" :key="i">
                  <td style="width:30px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="item.selected">
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>{{ item.customName }}</td>
                </tr>
                </tbody>
              </table>
              <table class="table">
                <thead>
                <tr>
                  <th style="width:30px">
                    <label class="form-checkbox">
                      <input type="checkbox"
                             @change="selectAllExpenses"
                             :checked="getExpenseSelected"
                             :indeterminate.prop="getExpenseSelected === null">
                      <i class="form-icon"></i>
                    </label>
                  </th>
                  <th>Selecionar todos os procedimentos</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in share.expenses" :key="i">
                  <td style="width:30px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="item.selected">
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>{{ item.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-primary btn-icon btn-icon-left mr-2"
                @click="saveScheduleShare"
                :disabled="share.saving" :class="{loading: share.saving}">
                <fa-icon class="mr-2" :icon="['fal', 'share-alt']" />
                Salvar e disponibilizar na web
              </button>
              <button
                class="btn btn-error btn-icon btn-icon-left"
                @click="deleteScheduleShare"
                v-if="form.shared.insurances.length > 0 || form.shared.expenses.length > 0"
                :disabled="share.deleting" :class="{loading: share.deleting}">
                <fa-icon class="mr-2" :icon="['fal', 'times']" />
                Apagar agenda compartilhada
              </button>
            </div>
          </div>
        </div>

        <div class="divider" data-content="" />

        <h6 class="h6">Gerenciamento de horários</h6>
        <div class="d-inline-block" v-if="schedules.length > 0">
          <label class="form-checkbox text-primary">
            <input type="checkbox"
                   @click="selectAll(itemSelectedAll === null
                           ? true : !itemSelectedAll)"
                   :checked="itemSelectedAll"
                   :disabled="schedules.length < 2"
                   :indeterminate.prop="itemSelectedAll === null">
            <i class="form-icon"/>Selecionar todos
          </label>
        </div>
        <div class="schedule-card-items">
          <div class="schedule-card-item add">
            <div class="item-content" @click="openSchedule">
              <fa-icon class="icon" :icon="['fal', 'plus']"></fa-icon>
              <div class="text-ellipsis">Adicionar horários</div>
            </div>
          </div>
          <div class="schedule-card-item"
               v-for="(item, i) in schedules" :key="i">
            <div class="item-header" :class="item.type">
              {{ item.type === 'week' ? 'Semanal' : 'Escala' }}
              <span class="item-check">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </span>
            </div>
            <div class="item-content" @click="editSchedule(item)">
              <div class="item-day" v-if="item.type === 'scale'">
                {{ item.day | date('DD')}}
              </div>
              <div v-else>
                {{ item.day.toString().includes('feira') ? 'Todas as' : 'Todos os' }}
              </div>
              <strong>
                <span v-if="item.type === 'week'">{{ item.day }}</span>
                <span v-else>{{ item.day | date('dddd') }}</span>
                <span v-if="item.type === 'week'">`s</span>
              </strong>
              <div v-if="item.type === 'scale'">{{ item.day | date('MMMM [de] YYYY') }}</div>
            </div>
            <div class="item-footer" @click="editSchedule(item)">
              <template v-if="item.ranges.length > 0">
                <small class="label label-item"
                       v-for="(range, i) in item.ranges.slice(0, 1)" :key="i">
                  {{ range.start }} - {{ range.end }}
                </small>
                <small class="label ml-1 tooltip"
                       :data-tooltip="getRangeNames(item.ranges).join('\n')"
                       v-if="item.ranges.length > 1">
                  + {{ item.ranges.length -1 }}
                </small>
              </template>
              <small class="label label-warning" v-else>Exclusivo encaixe</small>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-primary mr-1" @click="save"
          :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn mr-1" @click="$router.back()">
          Voltar
        </button>
        <button class="btn btn-error" :class="{loading: deleting}" v-if="itemSelected > 0"
                :disabled="deleting" @click="deleteMany">
          Excluir ({{ itemSelected }} horário{{ itemSelected === 1 ? '' : 's' }})
        </button>
      </div>
    </div>
    <st-schedule-modal :show="config.show" :data="config" @save="onSaveConfig"
                       @close="config.show = false" v-if="config.show"/>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { types as scheduleTypes } from '@/data/schedule-types';
import { mergeFrom } from '@/helpers/object';
import formMixin from 'src/mixins/form';
import StScheduleModal from './modals/Schedule.vue';

export default {
  components: {
    StScheduleModal,
  },
  mixins: [formMixin],
  data() {
    return {
      path: '/schedules',
      isNew: false,
      loading: false,
      saving: false,
      deleting: false,
      config: {
        show: false,
        id: '',
        scheduleId: '',
      },
      schedules: [],
      form: this.blankForm(),
      share: {
        show: false,
        loading: false,
        saving: false,
        deleting: false,
        insurances: [],
        expenses: [],
      },
      scheduleTypes,
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      await this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
      },
    };
  },
  computed: {
    itemSelected() {
      return this.schedules.filter(item => item.selected).length;
    },
    itemSelectedAll() {
      let actives = 0;
      actives = this.schedules
        .filter(item => item.selected === true).length;
      if ((actives === this.schedules.length) && this.schedules.length > 0) {
        return true;
      } if (actives > 0) {
        return null;
      }
      return false;
    },
    isShared() {
      return this.form.shared.insurances.length > 0 || this.form.shared.expenses.length > 0;
    },
    getInsuranceSelected() {
      if (!this.share.insurances || this.share.insurances.length === 0) {
        return false;
      }

      const selected = this.share.insurances.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.share.insurances.length) {
        return true;
      }
      return null;
    },
    getExpenseSelected() {
      if (!this.share.expenses || this.share.expenses.length === 0) {
        return false;
      }

      const selected = this.share.expenses.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.share.expenses.length) {
        return true;
      }
      return null;
    },
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
          this.schedules = data.schedules.map((item) => {
            // eslint-disable-next-line
            item.selected = false;
            return item;
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async openSchedule() {
      if (this.saving) {
        return;
      }

      if (!this.form.id) {
        if (this.form.name) {
          await this.save();
          if (!this.form.id) {
            return;
          }
        } else {
          this.$toast.show('Salve uma agenda', { type: 'error' });
          return;
        }
      }

      this.config = {
        show: true,
        id: '',
        day: '',
        scheduleType: '',
        scheduleId: this.form.id,
      };
    },
    selectAll(selected = true) {
      this.schedules = this.schedules
        .map((item) => {
          // eslint-disable-next-line no-param-reassign
          item.selected = selected;
          return item;
        });
    },
    editSchedule(item) {
      this.config = {
        show: true,
        id: item.id,
        day: item.day,
        scheduleType: item.type,
        scheduleId: this.form.id,
      };
    },
    onSaveConfig({ error = false } = {}) {
      this.config.show = false;
      setTimeout(this.load, error ? 10000 : 10);
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.saving) {
        return;
      }

      this.saving = true;

      const params = {
        name: this.form.name,
      };

      try {
        const { data: found } = await this.$http
          .get(this.path, { params });

        if (found.items.length > 0 && found.items[0].id !== this.form.id) {
          this.$toast.show('Agenda já adicionada', { type: 'error' });
          this.saving = false;
          return;
        }
      } catch (e) {
        this.$toast.show('Erro ao validar a agenda', { type: 'error' });
        this.saving = false;
        return;
      }

      const schedule = this.clone(this.form);

      const request = this.isNew
        ? this.$http.post(this.path, schedule)
        : this.$http.put(`${this.path}/${this.form.id}`, schedule);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/clinical${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    async deleteMany() {
      this.deleting = true;
      const ids = this.schedules
        .filter(item => item.selected)
        .map(({ id }) => id);

      this.$http.delete('/schedule-config', { data: { ids } })
        .then(() => {
          this.removeSchedules(ids);
        })
        .catch(() => {})
        .then(() => {
          this.deleting = false;
        });
    },
    removeSchedules(ids) {
      ids.forEach((scheduleId) => {
        const found = this.schedules
          .find(({ id }) => id === scheduleId);
        const idx = this.schedules.indexOf(found);
        this.schedules.splice(idx, 1);
      });
    },
    getRangeNames(items) {
      return items.slice(1)
        .map(item => `${item.start} - ${item.end}`);
    },
    selectAllInsurances(e) {
      this.share.insurances.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllExpenses(e) {
      this.share.expenses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    async loadShareData() {
      this.share.show = true;
      this.share.loading = true;

      if (this.share.insurances.length === 0) {
        await this.loadInsurances();
      }
      if (this.share.expenses.length === 0) {
        await this.loadExpenses();
      }
      this.sortActiveSharedResources();

      this.share.loading = false;
    },
    sortActiveSharedResources() {
      this.share.insurances.sort((a, b) => {
        if (a.selected) return -1;
        if (b.selected) return 1;
        return 0;
      });
      this.share.expenses.sort((a, b) => {
        if (a.selected) return -1;
        if (b.selected) return 1;
        return 0;
      });
    },
    async loadInsurances() {
      this.share.insurances = [];
      const params = {
        limit: 0,
        active: true,
      };

      try {
        const { items } = await this.apiGetInsurances(params);
        this.share.insurances = items.map(item => ({
          id: item.plan.id,
          name: item.name,
          customName: item.customName,
          selected: this.form.shared.insurances.some(id => id === item.plan.id),
        }));
      } catch (e) {
        this.$toast.error(e);
      }
    },
    apiGetInsurances(params) {
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => data);
    },
    async loadExpenses() {
      this.share.expenses = [];
      const params = {
        limit: 0,
        active: true,
      };

      try {
        const { items } = await this.apiGetExpenses(params);
        this.share.expenses = items.map(item => ({
          id: item.id,
          name: item.name,
          selected: this.form.shared.expenses.some(id => id === item.id),
        }));
      } catch (e) {
        this.$toast.error(e);
      }
    },
    apiGetExpenses(params) {
      return this.$httpX.get('/procedures', { params })
        .then(({ data }) => data);
    },
    async saveScheduleShare() {
      if (this.share.saving) {
        return;
      }

      if (!this.form.id) {
        if (this.form.name) {
          await this.save();
          if (!this.form.id) {
            return;
          }
        } else {
          this.$toast.show('Primeiro salve a agenda', { type: 'error' });
          return;
        }
      }

      const insurances = this.share.insurances
        .filter(item => item.selected)
        .map(item => item.id);

      const expenses = this.share.expenses
        .filter(item => item.selected)
        .map(item => item.id);

      if (insurances.length === 0) {
        this.$toast.show('Selecione ao menos um convênio!', { type: 'error' });
        return;
      }

      if (expenses.length === 0) {
        this.$toast.show('Selecione ao menos um procedimento!', { type: 'error' });
        return;
      }

      this.share.saving = true;

      const shareData = {
        insurances,
        expenses,
      };

      try {
        await this.$http.post(`/schedules/${this.form.id}/share`, shareData);
        this.$toast.show('Agenda compartilhada');
        this.form.shared = shareData;
        this.sortActiveSharedResources();
      } catch (e) {
        this.$toast.error(e);
      }

      this.share.saving = false;
    },
    async deleteScheduleShare() {
      if (this.share.deleting) {
        return;
      }

      this.share.deleting = true;

      try {
        await this.$http.delete(`/schedules/${this.form.id}/share`);
        this.$toast.show('Agenda excluída com sucesso');
        this.form.shared = this.blankForm().shared;
        this.share.show = false;
      } catch (e) {
        this.$toast.error(e);
      }

      this.share.deleting = false;
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: 'elective',
        communication: {
          email: false,
          whatsapp: true,
        },
        shared: {
          insurances: [],
          expenses: [],
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.schedule-page {
  .schedule-card-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$layout-spacing-sm) 0 (-$layout-spacing-sm);
  }
  .schedule-card-item {
    margin: 0 $layout-spacing-sm $layout-spacing $layout-spacing-sm;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $light-color;
    text-align: center;
    height: 8rem;
    width: 8rem;
    .item-header {
      border-radius: $border-radius $border-radius 0 0;
      color: #fff;
      position: relative;
      &.week {
        background: #007A84;
        border: $border-width solid #007A84;
      }
      &.scale {
        background: #f68100;
        border: $border-width solid #f68100;
      }
      .form-icon {
        height: .6rem;
        width: .6rem;
      }
      .item-check {
        position: absolute;
        right: $layout-spacing-lg;
        top: -.05rem;
        .form-checkbox {
          padding: 0;
          margin: 0;
          .form-icon {
            border: 0;
          }
        }
      }
    }
    .item-content, .item-footer {
      border-style: solid;
      border-color: $border-color;
      border-width: $border-width;
    }
    .item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border-style: solid;
      border-width: 0 $border-width 0 $border-width;
      .item-day {
        font-size: 25px;
        font-weight: bold;
      }
    }
    .item-footer {
      border-radius: 0 0 $border-radius $border-radius;
      display: flex;
      padding: $layout-spacing;
      justify-content: center;
    }
    .label-item {
      background-color: $secondary-color;
      color: $light-color;
    }
    &:hover {
      background-color: darken($light-color, 4);
    }
    &.add {
      background-color: darken($light-color, 4);
      .item-content {
        border-width: $border-width;
        border-radius: $border-radius;
      }
      .icon {
        width: auto;
        height: 2rem;
        margin-bottom: .6rem;
        color: slategray;
      }
      &:hover {
        background-color: #f0f1f4;
        .icon {
          color: $primary-color;
        }
      }
    }
  }
  .card-share {
    background-color: lighten($gray-color-light, 6%);
    border: $border-color solid $border-width;
    margin-top: $layout-spacing;
    table {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
    }
  }
}
</style>
