<template>
  <div class="range-group">
    <div class="range columns">
      <div class="column auto form-group"
           :class="{'has-error': $v.form.start.$error}">
        <label class="form-label">horário início</label>
        <input type="text" class="form-input text-center" placeholder="00:00"
               v-model="form.start" @blur="$v.form.start.$touch()"
               autocomplete="nope" v-mask="'00:00'">
      </div>
      <div class="column auto form-group"
           :class="{'has-error': $v.form.end.$error}">
        <label class="form-label">Horário término</label>
        <input type="text" class="form-input text-center" placeholder="00:00"
               v-mask="'00:00'" v-model="form.end"
               @blur="$v.form.end.$touch()" autocomplete="nope">
      </div>
      <div class="column auto form-group" v-if="!arrivalOrder"
           :class="{'has-error': $v.form.interval.$error}">
        <label class="form-label">Intervalo</label>
        <div class="input-group">
          <input type="text" class="form-input text-center" placeholder="00"
                 v-mask="'000'" v-model="form.interval"
                 @blur="$v.form.interval.$touch()" autocomplete="nope">
          <span class="input-group-addon text-gray">min</span>
        </div>
      </div>
      <div class="column col-auto form-group">
        <div class="range-trash tooltip tooltip-left"
             data-tooltip="Excluir faixa de horário" @click="removeRange()">
          <fa-icon :icon="['fal', 'trash']"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { hour, minHour } from '../../../../data/validators';

export default {
  props: {
    index: {
      type: Number,
    },
    arrivalOrder: {
      type: Boolean,
    },
    item: {
      start: {
        type: String,
      },
      end: {
        type: String,
      },
      interval: {
        type: String,
      },
    },
  },
  data() {
    return {
      form: {
        start: '',
        end: '',
        interval: '',
      },
    };
  },
  mounted() {
    this.form = this.item;
  },
  validations() {
    const rules = {
      form: {
        start: { hour, required },
        end: {
          hour,
          required,
          minHour: minHour(this.form.start),
        },
        interval: {},
      },
    };

    if (!this.arrivalOrder) {
      rules.form.interval = { required };
    }

    return rules;
  },
  methods: {
    removeRange() {
      this.$emit('removeRange', this.index);
    },
    validate() {
      this.$v.form.$touch();
      return !this.$v.form.$error;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.range-group {
  .range {
    align-items: center;
    background-color: lighten($gray-color-light, 4);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    display: flex;
    margin: $layout-spacing 0;
    padding: $layout-spacing-sm;
    .range-trash {
      color: $gray-color;
      font-size: 1.5em;
      &:hover {
        color: $error-color;
        cursor: pointer;
      }
    }
  }
}
</style>
