<template>
  <dx-modal :title="modalTitle"
            :value="data.show" @input="closeModal" id="modal-schedule">
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <div v-if="readonly">
        <div class="card card-schedule" v-if="form.type === 'scale'">
          <div class="card-header">
            <div class="card-title">Duplicar</div>
          </div>
          <div class="card-body">
            Você pode gerar novos horários com base nesta configuração.
            Para isso, clique no botão logo abaixo.
          </div>
          <div class="card-footer">
            <button class="btn btn-primary btn-block" @click="duplicateSchedule">Duplicar
            </button>
          </div>
        </div>
        <div class="card card-schedule">
          <div class="card-header">
            <div class="card-title">Configuração</div>
          </div>
          <div class="card-body">
            <template v-if="form.ranges.length">
              <div v-for="(range, r) in form.ranges" :key="r">
                Início: {{ range.start }} - Término: {{ range.end }} - intervalo:
                {{ range.interval }}/{{ range.interval }}min.
              </div>
            </template>
            <div v-else>Agenda exclusiva para encaixes</div>
          </div>
        </div>
        <div class="card card-schedule">
          <div class="card-header">
            <div class="card-title">Procedimentos</div>
          </div>
          <div class="card-body">
            <div v-for="(expense, e) in form.expenses" :key="e">
              {{ expense.name }}
            </div>
          </div>
        </div>
        <div class="card card-schedule">
          <div class="card-header">
            <div class="card-title">Convênios</div>
          </div>
          <div class="card-body">
            <div v-for="(insurance, i) in form.insurancePlans" :key="i">
              {{ insurance.customName }}
            </div>
          </div>
        </div>
        <div class="card card-schedule">
          <div class="card-header">
            <div class="card-title">Profissionais</div>
          </div>
          <div class="card-body">
            <div v-for="(professional, p) in form.professionals" :key="p">
              <span class="mr-1">{{ professional.name }}</span>
              <small class="text-gray" v-if="professional.specialty">
                ({{ professional.specialty.name }})
              </small>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="schedule-modal">
          <ul class="step">
            <template v-for="(item, i) in steps">
              <li class="step-item" :class="active(item.id)" :key="i">
                <a>{{ item.title }}</a>
              </li>
            </template>
          </ul>
          <div class="divider"></div>
          <div class="columns">
            <div class="column col-12">
              <template v-if="step === 1 && !form.id">
                <div class="modal-schedule-card-items">
                  <div class="modal-schedule-card-item">
                    <div class="item-content" @click="selectedType('week')">
                      <fa-icon class="icon" :icon="['fal', 'calendar-alt']"></fa-icon>
                      <strong>Horários recorrentes</strong>
                      <small class="text-dark mt-2">Utilize quando os horários forem se repetir
                        todas as semanas sem uma data de término</small>
                    </div>
                  </div>
                  <div class="modal-schedule-card-item">
                    <div class="item-content" @click="selectedType('scale')">
                      <fa-icon class="icon" :icon="['fal', 'calendar-day']"></fa-icon>
                      <strong>Horários personalizados</strong>
                      <small class="text-dark mt-2">Utilize quando os horários forem
                        de datas específicas</small>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="step === 2">
                <div class="h6 mb-2">
                  Atendimento {{ form.type === 'scale' ? 'por escala' : 'semanal' }}
                </div>
                <div class="columns" v-if="form.type === 'scale' && !form.id">
                  <div class="column col-4 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.startDate.$error}">
                    <label class="form-label">Data início</label>
                    <dx-input-date
                      id="start-date"
                      class="form-input text-center"
                      v-model="form.startDate"
                      @blur="$v.form.startDate.$touch()"
                    />
                    <template v-if="$v.form.startDate.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.startDate.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.startDate.date" >Data inválida</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.startDate.minDate">
                        Não pode ser inferior a data atual
                      </div>
                    </template>
                  </div>
                  <div class="column col-4 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.endDate.$error}">
                    <label class="form-label">Data término</label>
                    <dx-input-date
                      id="end-date"
                      class="form-input text-center"
                      v-model="form.endDate"
                      @blur="$v.form.endDate.$touch()"
                    />
                    <template v-if="$v.form.endDate.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.endDate.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.endDate.date" >Data inválida</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.endDate.minDate">
                        Não pode ser inferior a hoje
                      </div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.endDate.maxDate">
                        Deve ser inferior a 6 meses
                      </div>
                    </template>
                  </div>
                  <div class="column col-4 form-group"
                       :class="{'has-error': $v.form.repeat.$error}">
                    <label for="repeat" class="form-label">Repetir</label>
                    <select id="repeat" class="form-select"
                            v-model="form.repeat" @change="$v.form.repeat.$touch()">
                      <option :value="1">Toda semana</option>
                      <option :value="2">a cada 2 semanas</option>
                      <option :value="3">a cada 3 semanas</option>
                      <option :value="4">a cada 4 semanas</option>
                      <option :value="5">a cada 5 semanas</option>
                      <option :value="6">a cada 6 semanas</option>
                      <option :value="7">a cada 7 semanas</option>
                      <option :value="8">a cada 8 semanas</option>
                    </select>
                    <template v-if="$v.form.repeat.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.repeat.required">Campo obrigatório</div>
                    </template>
                  </div>
                </div>
                <div class="columns" v-if="!form.id">
                  <div class="column col-12 form-group"
                       :class="{'has-error': $v.form.weekDays.$error}">
                    <label for="week-days" class="form-label">
                      Escolha abaixo os dias da semana que estes horários irão se repetir
                    </label>
                    <div class="week-days" id="week-days">
                      <label class="form-checkbox form-inline"
                             v-for="(item, i) in weekDays" :key="i">
                        <input type="checkbox" v-model="item.selected">
                        <i class="form-icon"/>{{ item.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <label class="form-switch mb-2 mt-2">
                  <input type="checkbox" name="slot" v-model="form.slot">
                  <i class="form-icon"/> É uma agenda exclusiva para encaixes?
                </label>

                <div class="range" v-if="!form.id && !form.slot">
                  <div class="divider" v-if="!form.slot"></div>
                  <div>Escolha como irá funcionar o atendimento</div>
                  <label class="form-radio form-inline">
                    <input type="radio" name="type" v-model="rangeType" value="schedule">
                    <i class="form-icon"/> Em horários agendados
                  </label>
                  <label class="form-radio form-inline">
                    <input type="radio" name="type" v-model="rangeType" value="arrivalOrder">
                    <i class="form-icon"/> Por ordem de chegada
                  </label>
                </div>
                <div v-if="!form.slot && rangeType">
                  <div v-for="(range, i) in form.ranges" :key="range.key">
                    <range :item="range" :arrivalOrder="rangeType === 'arrivalOrder'"
                           :index="i" ref="timeRange" @removeRange="removeRange"/>
                  </div>
                  <div class="s-rounded text-light p-2 mb-1"
                       :class="rangesMessage.type === 'error' ? 'bg-error' : 'bg-warning'"
                       v-if="rangesMessage">
                    {{ rangesMessage.message }}
                  </div>
                  <span class="hover-link" v-if="rangeType === 'schedule'"
                        @click="addRange">Adicionar outra faixa de horário</span>
                </div>
                <div class="divider" />
                <label class="form-label">Observações adicionais
                  <textarea rows="2" class="form-input" v-model="form.notes"/>
                </label>
              </template>
              <template v-else-if="step === 3">
                <div class="h6">Lista de profissionais</div>
                <table class="table table-hover table-striped"
                       :class="{'has-error': $v.professionals.$error}">
                  <thead>
                  <tr>
                    <th>
                      <div class="form-group">
                        <label class="form-checkbox">
                          <input type="checkbox"
                                 @click="selectAllProfessionals(professionalSelectedAll() === null
                       ? true : !professionalSelectedAll())"
                                 :checked="professionalSelectedAll()"
                                 :indeterminate.prop="professionalSelectedAll() === null">
                          <i class="form-icon"/>
                        </label>
                      </div>
                    </th>
                    <th>Nome</th>
                    <th>Especialidade</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in professionals" :key="i">
                    <td class="text-center" width="40px">
                      <div class="form-group">
                        <label class="form-checkbox">
                          <input type="checkbox" v-model="item.selected">
                          <i class="form-icon"/>
                        </label>
                      </div>
                    </td>
                    <td @click="item.selected = !item.selected">{{ item.name }}</td>
                    <td @click="item.selected = !item.selected">{{ item.specialty.name }}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <template v-else-if="step === 4">
                <div class="h6">Lista de convênios</div>
                <label class="form-checkbox">
                  <input type="checkbox"
                         @change="loadProfessionalInsurances"
                         v-model="insuranceFilter">
                  Selecione apenas os convênios dos profissionais selecionados
                  <i class="form-icon"></i>
                </label>
                <div class="divider" />
                <table class="table table-hover table-striped"
                       :class="{'has-error': $v.insurances.$error}">
                  <thead>
                  <tr>
                    <th>
                      <div class="form-group">
                        <label class="form-checkbox">
                          <input type="checkbox"
                                 @click="selectAllInsurances(insuranceSelectedAll() === null
                             ? true : !insuranceSelectedAll())"
                                 :checked="insuranceSelectedAll()"
                                 :indeterminate.prop="insuranceSelectedAll() === null">
                          <i class="form-icon"></i>
                        </label>
                      </div>
                    </th>
                    <th>Nome</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in insurances" :key="i">
                    <td class="text-center" width="40px">
                      <div class="form-group">
                        <label class="form-checkbox">
                          <input type="checkbox" v-model="item.selected">
                          <i class="form-icon"></i>
                        </label>
                      </div>
                    </td>
                    <td @click="item.selected = !item.selected">{{ item.customName }}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <template v-else-if="step === 5">
                <div class="h6">Lista de procedimentos</div>
                <label class="form-checkbox">
                  <input type="checkbox"
                         @change="loadProfessionalExpenses"
                         v-model="expenseFilter">
                  Selecione apenas os procedimentos dos profissionais selecionados
                  <i class="form-icon"></i>
                </label>
                <div class="divider" />
                <table class="table table-hover table-striped"
                       :class="{'has-error': $v.expenses.$error}">
                  <thead>
                  <tr>
                    <th>
                      <div class="form-group">
                        <label class="form-checkbox">
                          <input type="checkbox"
                                 @click="selectAllExpenses(expenseSelectedAll() === null
                             ? true : !expenseSelectedAll())"
                                 :checked="expenseSelectedAll()"
                                 :indeterminate.prop="expenseSelectedAll() === null">
                          <i class="form-icon"></i>
                        </label>
                      </div>
                    </th>
                    <th>Nome</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in expenses" :key="i">
                    <td class="text-center" width="40px">
                      <div class="form-group">
                        <label class="form-checkbox">
                          <input type="checkbox" v-model="item.selected">
                          <i class="form-icon"></i>
                        </label>
                      </div>
                    </td>
                    <td @click="item.selected = !item.selected">{{ item.name }}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray float-left mr-1"
                v-if="readonly" @click="readonly = false">Editar
        </button>
        <button class="btn float-left btn-error mr-1"
                :disabled="deleting" :class="{loading: deleting}"
                @click="deleteHour" v-if="form.id && readonly">Excluir
        </button>
        <button class="btn btn-icon btn-gray mr-1"
                :disabled="saving"
                v-if="(steps.length === 5 ? step > 1 : step > 2) && !readonly"
                @click="previewStep">
          <fa-icon class="icon mr-2" :icon="['fal', 'chevron-left']"/>Voltar
        </button>
        <button class="btn btn-icon btn-gray mr-1" @click="nextStep"
                :disabled="saving"
                v-if="(step > 1 && step < 5) && !readonly">Avançar
          <fa-icon class="icon ml-2" :icon="['fal', 'chevron-right']"/>
        </button>
        <button class="btn btn-primary mr-1"
                v-if="(step === 5 || form.id) && !readonly"
                :disabled="saving" :class="{loading: saving}"
                @click="save">Salvar
        </button>
        <button class="btn"
                :disabled="saving"
                @click="closeModal">Sair
        </button>
      </template>
    </template>
  </dx-modal>
</template>

<script>
import formMixin from 'src/mixins/form';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import {
  date,
  minDate,
  maxDate,
} from 'src/data/validators';
import { randomHash } from 'src/helpers/index';
import * as professionalApi from '@/app/professional/api';
import Range from './Range.vue';

export default {
  components: {
    Range,
  },
  mixins: [formMixin],
  props: {
    data: {
      id: {
        type: String,
      },
      day: {
        type: String,
      },
      scheduleType: {
        type: String,
      },
      scheduleId: {
        type: String,
      },
      show: {
        type: Boolean,
        default: false,
      },
    },
  },
  data() {
    return {
      path: '/schedule-config',
      loading: false,
      saving: false,
      deleting: false,
      readonly: true,
      form: this.blankForm(),
      steps: [],
      step: 1,
      rangeType: 'schedule',
      weekDays: [
        {
          day: 0,
          name: 'Dom',
          selected: false,
        },
        {
          day: 1,
          name: 'Seg',
          selected: false,
        },
        {
          day: 2,
          name: 'Ter',
          selected: false,
        },
        {
          day: 3,
          name: 'Qua',
          selected: false,
        },
        {
          day: 4,
          name: 'Qui',
          selected: false,
        },
        {
          day: 5,
          name: 'Sex',
          selected: false,
        },
        {
          day: 6,
          name: 'Sáb',
          selected: false,
        },
      ],
      insuranceFilter: false,
      expenseFilter: false,
      insurances: [],
      professionals: [],
      expenses: [],
    };
  },
  watch: {
    step() {
      switch (this.step) {
        case 3:
          this.loadProfessionals();
          break;
        case 4:
          this.insuranceFilter = false;
          this.loadInsurances();
          break;
        case 5:
          this.expenseFilter = false;
          this.loadExpenses();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.readonly = !!this.data.id;
    this.form.id = this.data.id;
    this.loadSteps();
    if (this.form.id) {
      this.load();
    } else {
      this.addRange();
    }
  },
  computed: {
    modalTitle() {
      let title = 'Configuração';
      if (this.data.scheduleType === 'week') {
        title += ` semanal - ${this.data.day}`;
      } else if (this.data.scheduleType === 'scale') {
        title += ` - ${moment(this.data.day)
          .format('dddd, DD [de] MMMM [de] YYYY')}`;
      }
      return title;
    },
    rangesMessage() {
      const ranges = this.form.ranges.filter(({ start, end }) => (
        !!start && !!end && start.length === 5 && end.length === 5
      ));
      if (ranges.length > 1) {
        const hasDuplicates = ranges.some(range => (
          ranges.filter(({ start }) => start === range.start).length > 1
          || ranges.filter(({ end }) => end === range.end).length > 1
        ));
        if (hasDuplicates) {
          return {
            type: 'error',
            message: 'Erro: Horários de início ou término duplicados',
          };
        }

        const hasConflicts = ranges.some(range => (
          ranges.filter(({ start }) => (
            start >= range.start && start <= range.end
          )).length > 1
          || ranges.filter(({ end }) => (
            end >= range.start && end <= range.end
          )).length > 1
        ));
        if (hasConflicts) {
          return {
            type: 'warning',
            message: 'Aviso: Um ou mais períodos colidem',
          };
        }
      }

      return null;
    },
  },
  validations() {
    const rules = {
      form: {
        type: { required },
        startDate: {},
        endDate: {},
        repeat: {},
        weekDays: {},
      },
      insurances: {
        selected: () => this.insurances.some(item => item.selected),
      },
      expenses: {
        selected: () => this.expenses.some(item => item.selected),
      },
      professionals: {
        selected: () => this.professionals.some(item => item.selected),
      },
    };
    if (!this.form.id) {
      if (this.form.type === 'scale') {
        rules.form.startDate = { date, required, minDate: minDate() };
        rules.form.endDate = {
          date,
          required,
          minDate: minDate(this.form.startDate),
          maxDate: maxDate(moment(this.form.startDate).add(6, 'months')),
        };
        rules.form.repeat = { required };
      }
      const weekDays = this.weekDays
        .filter(item => item.selected);

      if (weekDays && weekDays.length === 0) {
        rules.form.weekDays = { required };
      }
    }

    rules.step2 = ['form'];
    rules.step3 = ['professionals'];
    rules.step4 = ['insurances'];
    rules.step5 = ['expenses'];

    return rules;
  },
  methods: {
    addRange() {
      this.form.ranges.push({
        start: '',
        end: '',
        interval: '',
      });
    },
    removeRange(i) {
      if (this.form.ranges.length > 1) {
        this.form.ranges.splice(i, 1);
      }
    },
    closeModal() {
      this.$emit('close');
    },
    load() {
      this.loading = true;
      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.rangeType = data.arrivalOrder ? 'arrivalOrder' : 'schedule';
          data.ranges = data.ranges.map((item) => {
            item.key = randomHash();
            return item;
          });
          data.slot = data.ranges.length === 0;
          this.form = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.step === 1) {
        return Promise.resolve();
      }

      const stepValidator = this.$v[`step${this.step}`];
      stepValidator.$touch();
      if (stepValidator.$error) {
        return Promise.resolve();
      }

      this.saving = true;

      const data = {
        id: this.form.id,
        type: this.form.type,
        scheduleId: this.data.scheduleId,
      };

      if (this.step >= 2) {
        data.startDate = this.form.startDate;
        data.endDate = this.form.endDate;
        data.arrivalOrder = this.rangeType === 'arrivalOrder';
        data.ranges = this.form.slot ? [] : this.form.ranges;
        data.notes = this.form.notes;
        data.repeat = this.form.repeat;
        data.days = [];

        const days = this.weekDays
          .filter(item => item.selected === true)
          .map(day => day.day);

        if (data.type === 'week') {
          data.days = days;
        } else {
          data.days = this.generateDays({
            startDate: data.startDate,
            endDate: data.endDate,
            weekDays: days,
            repeat: data.repeat,
          });
        }
      }

      if (this.step >= 3) {
        data.professionals = this.professionals
          .filter(item => item.selected === true);
      }

      if (this.step >= 4) {
        data.insurancePlans = this.insurances
          .filter(item => item.selected === true)
          .map(insurance => insurance.plan.id);
      }

      if (this.step >= 5) {
        data.expenses = this.expenses
          .filter(item => item.selected === true)
          .map(expense => expense.id);
      }

      const request = !this.form.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${this.form.id}`, data);

      return request
        .then(() => {
          this.$emit('save', { error: false });
        })
        .catch((e) => {
          if (e.code && e.code === 'ECONNABORTED') {
            this.$toast.show('Conexão abortada, verifique se as informações foram salvas', {
              type: 'warning',
              timeout: 10000,
            });
            this.$emit('save', { error: true });
          } else {
            this.$toast.show('Erro ao salvar a configuração', {
              type: 'error',
              timeout: 10000,
            });
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
    deleteHour() {
      if (!this.form.id) {
        return;
      }

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este horário?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              return this.$http.delete(`${this.path}/${this.form.id}`)
                .catch(() => {})
                .then(() => {
                  close();
                  this.deleting = false;
                  this.$emit('save');
                });
            },
          },
        ],
      });
    },
    async loadInsurances() {
      this.loading = true;
      this.insurances = [];
      const params = {
        limit: 0,
        active: true,
      };

      await this.apiGetInsurances(params)
        .then((data) => {
          this.insurances = data.items
            .map(item => ({
              id: item.id,
              name: item.name,
              customName: item.customName,
              selected: false,
              plan: {
                id: item.plan.id,
                name: item.plan.name,
              },
            }));
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });

      return this.insurances
        .filter(item => this.form.insurancePlans
          .some(insurance => insurance.plan.id === item.plan.id))
        // eslint-disable-next-line no-return-assign,no-param-reassign
        .map(item => item.selected = true);
    },
    apiGetInsurances(params) {
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => data);
    },
    async loadExpenses() {
      this.loading = true;
      this.expenses = [];
      const params = {
        limit: 0,
        active: true,
      };
      await this.apiGetExpenses(params)
        .then((data) => {
          this.expenses = data.items
            .map(item => ({
              id: item.id,
              name: item.name,
              selected: false,
            }));
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });

      return this.expenses
        .filter(item => this.form.expenses
          .some(insurance => insurance.id === item.id))
        // eslint-disable-next-line no-return-assign,no-param-reassign
        .map(item => item.selected = true);
    },
    apiGetExpenses(params) {
      return this.$httpX.get('/procedures', { params })
        .then(({ data }) => data);
    },
    async loadProfessionals() {
      this.loading = true;
      this.professionals = [];

      await professionalApi.allActive()
        .then((data) => {
          this.professionals = data.items
            .reduce((result, item) => {
              if (item.professional.specialties && item.professional.specialties.length > 0) {
                item.professional.specialties.forEach((specialty) => {
                  result.push({
                    id: item.id,
                    name: item.name,
                    specialty,
                    selected: false,
                  });
                });
              } else {
                result.push({
                  id: item.id,
                  name: item.name,
                  specialty: {},
                  selected: false,
                });
              }
              return result;
            }, []);
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loading = false;
        });

      return this.professionals
        .filter(item => this.form.professionals
          .some(professional => professional.id === item.id
            && item.specialty.code === professional.specialty.code))
        // eslint-disable-next-line no-return-assign,no-param-reassign
        .map(item => item.selected = true);
    },
    active(id) {
      if (id === this.step) {
        return 'active';
      }
      return '';
    },
    loadSteps() {
      if (!this.form.id) {
        this.steps.push({ id: 1, title: 'Tipo' });
        this.step = 1;
      } else {
        this.step = 2;
      }
      this.steps.push(
        { id: 2, title: 'Horário' },
        { id: 3, title: 'Profissionais' },
        { id: 4, title: 'Convênios' },
        { id: 5, title: 'Procedimentos' },
      );
    },
    previewStep() {
      const preview = this.steps.find(item => item.id === (this.step - 1));
      this.step = preview.id;
    },
    nextStep() {
      if (this.step > 1) {
        const stepValidator = this.$v[`step${this.step}`];
        stepValidator.$touch();
        if (stepValidator.$error) {
          return;
        }
        if (
          this.step === 2
          && !this.form.slot
          && !this.$refs.timeRange.every(item => item.validate())
        ) {
          return;
        }
      }
      const next = this.steps.find(item => item.id === (this.step + 1));
      this.step = next.id;
    },
    selectedType(type) {
      this.form.type = type;
      this.nextStep();
    },
    selectAllInsurances(selected = true) {
      this.insurances = this.insurances.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.selected = selected;
        return item;
      });
    },
    insuranceSelectedAll() {
      let actives = 0;
      let activeLen = 0;
      activeLen = this.insurances.length;
      actives = this.insurances.filter(item => item.selected).length;

      if (actives === activeLen) {
        return true;
      } if (actives > 0) {
        return null;
      }
      return false;
    },
    selectAllProfessionals(selected = true) {
      this.professionals = this.professionals.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.selected = selected;
        return item;
      });
    },
    professionalSelectedAll() {
      let actives = 0;
      let activeLen = 0;
      activeLen = this.professionals.length;
      actives = this.professionals.filter(item => item.selected).length;

      if (actives === activeLen) {
        return true;
      } if (actives > 0) {
        return null;
      }
      return false;
    },
    selectAllExpenses(selected = true) {
      this.expenses = this.expenses.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.selected = selected;
        return item;
      });
    },
    expenseSelectedAll() {
      let actives = 0;
      let activeLen = 0;
      activeLen = this.expenses.length;
      actives = this.expenses.filter(item => item.selected).length;

      if (actives === activeLen) {
        return true;
      } if (actives > 0) {
        return null;
      }
      return false;
    },
    generateDays(params) {
      const startDate = moment(params.startDate);
      const endDate = moment(params.endDate);
      params.repeat = params.repeat || 1;
      params.weekDays = params.weekDays || [];

      const ctrl = {};
      const days = [];

      while (startDate.isSameOrBefore(endDate)) {
        if (Object.keys(ctrl).length === 0) {
          [0, 1, 2, 3, 4, 5, 6].forEach((day) => {
            ctrl[day] = startDate.day() > day ? 1 : 0;
          });
        }

        if (
          params.weekDays.includes(startDate.day())
          && ctrl[startDate.day()] % params.repeat === 0
        ) {
          days.push(startDate.format('YYYY-MM-DD'));
        }

        ctrl[startDate.day()] += 1;
        startDate.add(1, 'day');
      }

      return days;
    },
    duplicateSchedule() {
      this.readonly = false;
      this.form.id = '';
      this.form.type = 'scale';
      this.form.repeat = 1;
    },
    loadProfessionalInsurances() {
      this.insurances.forEach((item) => {
        item.selected = false;
      });

      if (!this.insuranceFilter) {
        return false;
      }

      const professionalIds = this.professionals
        .filter(({ selected }) => selected)
        .map(({ id }) => id);

      const params = {
        professionalIds: professionalIds.join(','),
      };

      return this.$http
        .get('/professional-insurances', { params })
        .then(({ data }) => {
          const planIds = data.items.map(({ insurance }) => insurance.plan.id);

          this.insurances
            .filter(item => planIds.includes(item.plan.id))
            .forEach((item) => {
              item.selected = true;
            });

          this.insurances.sort((a, b) => {
            if (a.selected && !b.selected) return -1;
            if (b.selected && !a.selected) return 1;
            return 0;
          });
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    loadProfessionalExpenses() {
      this.expenses.forEach((item) => {
        item.selected = false;
      });

      if (!this.expenseFilter) {
        return false;
      }

      const professionalIds = this.professionals
        .filter(({ selected }) => selected)
        .map(({ id }) => id);

      const params = {
        professionalIds: professionalIds.join(','),
      };

      return this.$http
        .get('/professional-procedures', { params })
        .then(({ data }) => {
          const expenseIds = data.items.map(({ id }) => id);

          this.expenses
            .filter(item => expenseIds.includes(item.id))
            .forEach((item) => {
              item.selected = true;
            });

          this.expenses.sort((a, b) => {
            if (a.selected && !b.selected) return -1;
            if (b.selected && !a.selected) return 1;
            return 0;
          });
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    blankForm() {
      return {
        id: '',
        type: '',
        startDate: '',
        endDate: '',
        arrivalOrder: false,
        slot: false,
        ranges: [],
        notes: '',
        repeat: 1,
        insurancePlans: [],
        professionals: [],
        expenses: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.schedule-modal {
  .schedule-resume {
    background-color: lighten($gray-color-light, 4);
  }
  .range {
    margin-top: $layout-spacing-lg;
  }
}
.modal-schedule-card-items {
  display: flex;
}
.modal-schedule-card-item {
  background-color: darken($light-color, 2);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 8rem;
  margin: $layout-spacing $layout-spacing-sm 0 $layout-spacing-sm;
  text-align: center;
  width: 50%;
  .item-content {
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: $layout-spacing;
  }
  .icon {
    color: slategray;
    height: 2rem;
    margin-bottom: .6rem;
    width: auto;
  }
  &:hover {
    background-color: darken($light-color, 5);
  }
}
#modal-schedule {
  .week-days {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-around;
  }
  .card-schedule {
    background-color: lighten($gray-color-light, 5%);
    margin-bottom: $layout-spacing;
  }
}
</style>
